import React from "react"
import ConditionalLayout from "../components/modal"
import SEO from "../components/seo"
import Detail from "../components/detail"

const data = [
  "Makeup",
  "Body wash and soap",
  "Toothpaste",
  "Deodorant",
  "Sanitary products",
  "Sunscreen",
  "First aid kit",
  "Pain relief",
]

const BathroomPage = () => (
  <ConditionalLayout>
    <SEO title="Bathroom" />
    <Detail
      name="Bathroom"
      subtitle="I help you find clean options that the entire family can feel good about using."
      data={data}
    />
  </ConditionalLayout>
)

export default BathroomPage
