exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bathroom-tsx": () => import("./../../../src/pages/bathroom.tsx" /* webpackChunkName: "component---src-pages-bathroom-tsx" */),
  "component---src-pages-bedroom-tsx": () => import("./../../../src/pages/bedroom.tsx" /* webpackChunkName: "component---src-pages-bedroom-tsx" */),
  "component---src-pages-cleaning-tsx": () => import("./../../../src/pages/cleaning.tsx" /* webpackChunkName: "component---src-pages-cleaning-tsx" */),
  "component---src-pages-food-tsx": () => import("./../../../src/pages/food.tsx" /* webpackChunkName: "component---src-pages-food-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-tsx": () => import("./../../../src/pages/kitchen.tsx" /* webpackChunkName: "component---src-pages-kitchen-tsx" */),
  "component---src-pages-nursery-tsx": () => import("./../../../src/pages/nursery.tsx" /* webpackChunkName: "component---src-pages-nursery-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */)
}

