import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

const Check = ({body}) => (
  <p className="flex items-center -mx-2">
    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 mr-2 text-sky-700 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>

    <span className="mx-2 text-slate-700 text-2xl font-['Playfair-display']">{body}</span>
  </p>
)

function Hero() {
  const [responseMessage, setResponseMessage] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const response = await fetch("https://formbold.com/s/oJpge", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    });
    const result = await response.json();

    if (response.ok) {
      setResponseMessage(result.message);
      event.target.reset();
    }
    setResponseMessage(result.message || "Something went wrong");
    setTimeout(() => setResponseMessage(""), 3000);
  }

  return (
    <section className="bg-white pt-36">
      <div className="container mb-6 lg:mb-0 flex flex-col px-0 lg:px-6 md:px-0 mx-auto space-y-6 lg:py-16 lg:items-center">
        <div className="items-center justify-center flex flex-1 flex-col w-full">
          <h1 className="font-['Montserrat'] text-sky-800 font-light text-3xl text-[28px] lg:text-5xl text-center">
            Let's detoxify your home
          </h1>
          <h2 className="font-['Playfair-display'] text-sky-800 mb-4 lg:mb-0 text-xl font-bold lg:text-3xl capitalize mt-2 lg:mt-4 tracking-widest">
            ONE SWAP AT A TIME
          </h2>
        </div>
      </div>

      <div id="wide-div">
        <div className="flex flex-col px-6 md:px-0 mx-auto lg:flex-row lg:py-16 lg:items-center bg-slate-50">
          {/* Image */}
          <div className="lg:pl-10 overflow-hidden rounded-lg flex items-center justify-center w-full h-96 lg:h-[650px] lg:w-1/2">
            <StaticImage imgStyle={{ borderRadius: 12 }} className="overflow-hidden object-cover lg:w-[480px] w-full h-full mx-auto rounded-lg lg:max-w-2xl" src="../images/eleonore-3.jpg" alt="glasses photo" />
          </div>

          {/* Content */}
          <div className="lg:-mt-20 lg:-ml-16 items-start flex flex-col w-full lg:w-1/2 text-white px-8 py-8">
            <div className="lg:max-w-lg">              
                <div className="mt-8 space-y-8">
                  <Check body="In person or Zoom consultation" />
                  <Check body="Personalized swaps" />
                  <Check body="Options for every budget" />
                </div>
            </div>

            <div className="w-full mt-12 bg-transparent border rounded-md lg:max-w-sm focus-within:border-blue-400 focus-within:ring focus-within:ring-blue-300 focus-within:ring-opacity-40">
                <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row">
                    <input type="email" placeholder="Enter your email address" className="flex-1 h-12 px-4 py-2 m-1 text-gray-700 placeholder-gray-400 bg-transparent border-none appearance-none text-lg focus:outline-none focus:placeholder-transparent focus:ring-0" /> 
                    
                    <button type="button" className="font-['Montserrat'] h-12 px-5 py-3 m-1 text-white transition-colors duration-200 transform bg-sky-800 rounded-md hover:bg-sky-800/80 focus:outline-none focus:bg-blue-400">
                        Subscribe
                    </button>
                </form>
            </div>
            {responseMessage ? 
                  <div className="flex bg-green-100 py-5 px-4 rounded-xl mt-4">
                    <p className="text-green-800">{responseMessage}</p>
                  </div> : null
            }
          </div>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
