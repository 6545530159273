import { StaticImage } from "gatsby-plugin-image";
import React from "react"

function About() {
  return (
    <div id="about-me" className="py-32 bg-white">  
      <div className="container m-auto text-gray-600 px-5 md:px-0">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:gap-12">
            <div className="md:5/12 lg:w-5/12">
              <StaticImage imgStyle={{ borderRadius: 12 }} 
                src="../images/eleonore-2.jpg"
                alt="image" loading="lazy" />
            </div>
            <div className="md:7/12 lg:w-6/12 pt-4">
              <h2 className="text-4xl font-['Playfair-display'] text-sky-800 font-bold tracking-wide">About me</h2>
              <p className="mt-6 text-gray-600 text-base text-[17px]">
              I was born in Paris, France and grew up between Paris & New York City. In September 2020, I moved to Miami with my French-Italian husband and our family. I am a mother of two and recently a dog mom to our adorable boxer mix named Coconut. 
                <br></br><br></br>
                My husband, children, and pup are my greatest blessing. Building a home that is filled with love, joy and safety has always been my top priority. I am deeply passionate about what I put in and on my body, as well as the products I use in my home. When I was pregnant with our son Henri in 2017 I began researching and learning about the impact of the toxins we absorb through food, products and household supplies. I made it my mission to learn how to shop and make informed decisions about the products that enter our home as well as the food we eat. 
                <br></br><br></br>
                Stress is the biggest threat to our health. My goal is to empower you so that your new journey into health is informed, effortless and fun. Eliminating all toxins from your life and living in a germ free environment is an impossible feat and not the goal. Small changes make an incredible impact; one swap at a time! 
                <br></br><br></br>
                Let me help you on this journey into non-toxic living and assist you in making healthy changes where needed.
                <br></br><br></br>
                I look forward to working with you!
              </p>
            </div>
          </div>
      </div>
    </div>
  )
}
export default About;