import React from "react"
import ConditionalLayout from "../components/modal"
import SEO from "../components/seo"
import Detail from "../components/detail"

const data = [
  "Mattress",
  "Pillows",
  "Air Filter",
  "Rugs",
  "Furniture",
  "Bed frame",
]

const BedroomPage = () => (
  <ConditionalLayout>
    <SEO title="Bedroom" />
    <Detail
      name="Bedroom"
      subtitle="We spend most of our life sleeping! A non toxic bedroom is often the best place to start. Let me create a clean space that will allow you to get the restorative sleep you deserve. "
      data={data}
    />
  </ConditionalLayout>
)

export default BedroomPage
