import React from "react"
import ConditionalLayout from "../components/modal"
import SEO from "../components/seo"
import Detail from "../components/detail"

const data = [
  "Healthy snacks for kids",
  "Coffee and tea",
  "Chips and popcorn",
  "Nut milks",
  "Pantry staples",
  "Sweeteners",
]

const FoodPage = () => (
  <ConditionalLayout>
    <SEO title="Food" />
    <Detail
      name="Food"
      subtitle="I help you swap out your favorite foods and condiments for healthier options that are just as tasty. I show you what to look for when reading labels so that you can be confident about your food choices."
      data={data}
    />
  </ConditionalLayout>
)

export default FoodPage
