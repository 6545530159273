import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import { Link } from 'gatsby'
// import { Link } from "gatsby-plugin-modal-routing-3"
import { useState, useRef, useEffect } from 'react';

const data = {
  resources: [
    {
      key: "134asdfasdfasdf",
      title: "Bedroom",
      link: "/bedroom/",
      imageUrl: "https://images.unsplash.com/photo-1445991842772-097fea258e7b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxfDB8MXxhbGx8fHx8fHx8fA&ixlib=rb-1.2.1&q=80&w=1080&utm_source=unsplash_source&utm_medium=referral&utm_campaign=api-credit"
    },
    {
      key: "902834ns0d9f809",
      title: "Cleaning & Laundry",
      link: "/cleaning/",
      imageUrl: "https://images.unsplash.com/photo-1528740561666-dc2479dc08ab?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3MzU2NDYyMQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"
    },
    {
      key: "183nas9d0asdj123",
      title: "Kitchen",
      link: "/kitchen/",
      imageUrl: "https://images.unsplash.com/photo-1556911220-bff31c812dba?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3Mzg4NzQ5Mg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"
      // imageUrl: "https://images.unsplash.com/photo-1556911220-bff31c812dba?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3MzUyNjUzNQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"
    },
    {
      key: "908nm2njjn1j2212",
      title: "Bathroom",
      link: "/bathroom/",
      imageUrl: "https://images.unsplash.com/photo-1584622650111-993a426fbf0a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3MzUzMTg1MQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"
    },
    {
      key: "93243mansdnasdsankd156643",
      title: "Food",
      link: "/food/",
      imageUrl: "https://images.unsplash.com/photo-1563865436874-9aef32095fad?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3Mzg3OTUyNQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"
    },
    {
      key: "234mansdasd981923asdas",
      title: "Nursery",
      link: "/nursery/",
      imageUrl: "https://images.unsplash.com/photo-1634120790908-dee682a6d205?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3Mzk4NTczMQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"
    }
  ]
}

const Carousel = () => {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);
  const carouselItemRef = useRef(null);
  const [width, setWidth] = useState(0);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      width * currentIndex <= maxScrollWidth.current
      // (carousel.current.offsetWidth * currentIndex / 2) <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        width * currentIndex >= maxScrollWidth.current
        // (carousel.current.offsetWidth * currentIndex /2) >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      // carousel.current.scrollLeft = (carousel.current.offsetWidth/2)  * currentIndex;
      carousel.current.scrollLeft = width * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    function handleWindowResize() {
      setWidth(carouselItemRef.current.clientWidth);
    }
  
    window.addEventListener('resize', handleWindowResize);
    
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;

    handleWindowResize()

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="carousel relative my-12 mx-auto pt-12">
      <div id="services" className="absolute -top-[100px] left-0"></div>
      <h2 className="font-['Playfair-display'] text-4xl leading-8 font-semibold mb-8 text-sky-800 pb-12 tracking-wide">
        Explore Services
      </h2>
      <div className="relative overflow-hidden">
        <div className="flex justify-between absolute top left w-full h-full">
          <button
            onClick={movePrev}
            className="hover:bg-black/30 text-white w-10 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled('prev')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-20 -ml-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span className="sr-only">Prev</span>
          </button>
          <button
            onClick={moveNext}
            className="hover:bg-black/30 text-white w-10 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled('next')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-20 -ml-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
            <span className="sr-only">Next</span>
          </button>
        </div>
        <div
          ref={carousel}
          className="carousel-container relative flex gap-3 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
        >
          {data.resources.map((resource) => {
            return (
              <div
                key={resource.key}
                className="carousel-item text-center relative w-[300px] h-[300px] lg:w-[500px] lg:h-[500px] snap-start"
                ref={carouselItemRef}
              >
                <Link
                  to={resource.link}
                  state={{
                    noScroll: true, modal: true
                  }}
                  className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
                  style={{ backgroundImage: `url(${resource.imageUrl || ''})` }}
                >
                  <img
                    src={resource.imageUrl || ''}
                    alt={resource.title}
                    className="w-full aspect-square hidden"
                  />
                </Link>
                <Link to={resource.link}
                  state={{
                    noScroll: true, modal: true
                  }}
                  className="flex items-center justify-center h-full w-full opacity-100 aspect-square absolute top-0 left-0 transition-opacity duration-300 hover:bg-black/40 bg-black/30 z-10"
                  >
                  <h3 className="text-white py-6 px-3 my-auto mx-auto text-2xl lg:text-5xl">
                    {resource.title}
                  </h3>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;