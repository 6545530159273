import React from "react"
import ConditionalLayout from "../components/modal"
import SEO from "../components/seo"
import Detail from "../components/detail"

const data = [
  "Crib & Travel Crib",
  "Mattress",
  "Diapers & Wipes",
  "Rocking chair",
  "Rugs & Playmats",
  "Carseat",
  "Stroller",
]

const NurseryPage = () => (
  <ConditionalLayout>
    <SEO title="Nursery" />
    <Detail
      name="Nursery"
      subtitle="I can help put together the nursery and/or create your baby shower list for you. Let me take care of all of the annoying stuff so that you can focus on yourself and enjoy this magical time."
      data={data}
    />
  </ConditionalLayout>
)

export default NurseryPage
