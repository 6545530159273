import React from "react"
import ConditionalLayout from "../components/modal"
import SEO from "../components/seo"
import Detail from "../components/detail"

const data = [
  "Glassware",
  "Utensils",
  "Water filters and pitchers",
  "Pots and pans",
  "Food",
]

const KitchenPage = () => (
  <ConditionalLayout>
    <SEO title="Kitchen" />
    <Detail
      name="Kitchen"
      subtitle="I help you swap out all of your kitchen utensils, accessories, cookware."
      data={data}
    />
  </ConditionalLayout>
)

export default KitchenPage
