import React, {useState} from "react"

function Contact() {
  const [responseMessage, setResponseMessage] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const response = await fetch("https://formbold.com/s/3nQlp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    });
    const result = await response.json();

    if (response.ok) {
      setResponseMessage(result.message);
      event.target.reset();
    }
    setResponseMessage(result.message || "Something went wrong");
    setTimeout(() => setResponseMessage(""), 3000);
  }

  return (
    <div id="contact" className="bg-white min-w-full py-20 relative flex items-center justify-center">
      <div className="flex-col flex bg-white w-full max-w-screen-md items-center justify-center">
        <p className="text-4xl text-sky-800 text-center font-bold tracking-wide">Contact Me</p>
        <form onSubmit={handleSubmit} className="w-full mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div>
            <label htmlFor="first-name" className="block text-base font-medium text-gray-700">
              First name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                id="first-name"
                required
                className="py-4 border-2 block w-full border-gray-300 rounded-xl focus:border-gray-500 focus:ring-gray-500 sm:text-lg"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-base font-medium text-gray-700">
              Last name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="last-name"
                id="last-name"
                required
                className="block border-2 w-full rounded-xl border-gray-300 py-4 focus:border-gray-500 focus:ring-gray-500 sm:text-lg"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-base font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="py-4 border-2 block w-full rounded-xl border-gray-300 focus:border-gray-500 focus:ring-gray-500 sm:text-lg"
              />
            </div>
          </div>
          
          <div className="sm:col-span-2">
            <div className="flex justify-between">
              <label htmlFor="how-can-we-help" className="block text-base font-medium text-gray-700">
                How can I help you?
              </label>
            </div>
            <div className="mt-1">
              <textarea
                id="how-can-we-help"
                name="how-can-we-help"
                aria-describedby="how-can-we-help-description"
                rows={8}
                className="py-4 border-2 block w-full rounded-xl border-gray-300 focus:border-gray-500 focus:ring-gray-500 sm:text-lg"
                defaultValue={''}
                required
              />
            </div>
          </div>

          <div className="text-center sm:col-span-2">
            <button
              type="submit"
              className="inline-flex justify-center bg-sky-800 font-['Montserrat'] hover:bg-sky-800/80 rounded-xl border border-transparent py-4 px-8 text-lg font-medium text-white shadow-sm hover:bg-back/80 focus:outline-none focus:ring-2 focus:ring-grape-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
          {responseMessage ? 
          <div className="bg-green-100 py-5 px-4 rounded-xl">
            <p className="text-green-800">{responseMessage}</p>
          </div> : null}
        </form>
      </div>
    </div>
  )
}
export default Contact;