import React from "react"
// import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage, Img, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha";
import SEO from "../components/seo"
import Layout from "../components/layout"


const ProductCard = ({product}) => {
  const {title, desc, price, imageAlt, unit, src} = product;
  const img = getImage(src);

  return (
  <>
  <div className="min-w-screen bg-white flex items-center py-5 lg:p-10 relative">
      <div className="w-full border-2 border-slate-100 max-w-6xl rounded-lg bg-white shadow-md lg:shadow-lg p-5 lg:p-12 mx-auto text-slate-700 relative md:text-left">
          <div className="md:flex items-start -mx-10">
              <div className="w-full md:w-1/2 px-10 mb-10 md:mb-0">
                  <div className="relative">
                      <GatsbyImage imgStyle={{ borderRadius: 12 }} className="w-full relative" image={img} alt={imageAlt} />
                  </div>
              </div>
              <div className="w-full md:w-1/2 px-10">
                  <div className="mb-5">
                      <h1 className="font-['Playfair-display'] font-bold uppercase text-2xl mb-3 text-sky-800">{title}</h1>
                      <p className="text-base">{desc}</p>
                  </div>

                  <div className="mb-5">
                    <h3 className="text-base font-medium text-gray-900">Ingredients</h3>
                    <div className="mt-4">
                      <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                        {product.ingredients.map((highlight) => (
                          <li key={highlight} className="text-gray-400">
                            <span className="text-gray-600">{highlight}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  
                  {product.benefits.length > 0 ?
                  (<div className="mb-5">
                    <h3 className="text-base font-medium text-gray-900">Health Benefits</h3>
                    <div className="mt-4">
                      <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                        {product.benefits.map((highlight) => (
                          <li key={highlight} className="text-gray-400">
                            <span className="text-gray-600">{highlight}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>)
                  : null
                  }

                  <div>
                      <div className="inline-block align-bottom mr-5">
                          <span className="text-2xl leading-none align-baseline">$</span>
                          <span className="font-bold text-5xl leading-none align-baseline">{price}</span>
                          <span className="text-xl leading-none align-baseline">/ {unit}</span>
                      </div>
                      {/* <div className="inline-block align-bottom">
                          <button className="bg-yellow-300 opacity-75 hover:opacity-100 text-yellow-900 hover:text-gray-900 rounded-full px-10 py-2 font-semibold"><i className="mdi mdi-cart -ml-2 mr-2"></i> BUY NOW</button>
                      </div> */}
                  </div>
              </div>
          </div>
      </div>
  </div>
  </>
)}

const IncrementButtons = ({name, count, decrement, increment}) => (
  <div className={`${name} shrink-0 h-10 w-32`}>
    <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
      <button
        onClick={decrement}
        className=" bg-sky-800 text-white hover:bg-sky-800/80 h-full rounded-l-md cursor-pointer outline-none flex items-center justify-center px-2 py-4"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="flex w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
        </svg>
      </button>
      <input
        name={name}
        type="number"
        value={count}
        required 
        className="font-['Montserrat'] focus:outline-none text-center w-full bg-white font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-sky-900  outline-none"
      />
      <button
        onClick={increment}
        className="bg-sky-800 text-white hover:bg-sky-800/80 h-full rounded-r cursor-pointer flex items-center justify-center px-2"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 flex">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
        </svg>
      </button>
    </div>
  </div>
)



const Shop = () => {
  const data = useStaticQuery(graphql`
    query ShopItemsQuery {
      allShopItemsJson {
        edges {
          node {
            id
            title
            desc
            price
            ingredients
            benefits
            imageAlt
            unit
            src {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  `)

  const recaptchaRef = React.createRef();
  const [responseMessage, setResponseMessage] = React.useState("");
  const [turmCount, setTurmCount] = React.useState(0);
  const [bananaLoafCount, setBananaLoafCount] = React.useState(0);
  const [turmSubCount, setTurmSubCount] = React.useState(0);
  const [gumCount, setGumCount] = React.useState(0);
  const decrementTCounter = (e) => {
    e.preventDefault();
    setTurmCount(turmCount - 1)
  };
  const incrementTCounter = (e) => {
    e.preventDefault();
    setTurmCount(turmCount + 1)
  };
  const incrementTSubCounter = (e) => {
    e.preventDefault();
    setTurmSubCount(turmSubCount + 1)
  };
  const decrementTSubCounter = (e) => {
    e.preventDefault();
    setTurmSubCount(turmSubCount - 1)
  };
  const decrementGCounter = (e) => {
    e.preventDefault();
    setGumCount(gumCount - 1)
  };
  const incrementGCounter = (e) => {
    e.preventDefault();
    setGumCount(gumCount + 1)
  };

  const incrementBCounter = (e) => {
    e.preventDefault();
    setBananaLoafCount(bananaLoafCount + 1)
  };
  const decrementBCounter = (e) => {
    e.preventDefault();
    setBananaLoafCount(bananaLoafCount - 1)
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const object = {...Object.fromEntries(formData), 'g-recaptcha-response': recaptchaRef.current.getValue()};
    const json = JSON.stringify(object);

    const response = await fetch("https://formbold.com/s/9EEpz", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    });
    const result = await response.json();

    if (response.ok) {
      setResponseMessage(result.message);
      event.target.reset();
    }
    setResponseMessage(result.message || "Something went wrong");
    setTimeout(() => setResponseMessage(""), 3000);
  }

  return (
  <Layout padding={0}>
    <SEO title="Shop" />
    <p>Shop</p>
    <div className="mt-32">
      {data.allShopItemsJson.edges.map(e => (
        <ProductCard product={e.node} key={e.node.id} />
      ))}
    </div>

    <div className="bg-white min-w-full py-20 relative flex items-center justify-center">
      <div className="flex-col flex bg-white w-full max-w-screen-md items-center justify-center">
        <p className="text-4xl text-sky-800 text-center font-bold tracking-wide font-['Playfair-display']">Place an order</p>
        <div className="flex flex-row items-center mt-3">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-sky-800">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
          </svg>
          <p className="ml-2 text-xl text-sky-800 text-center font-bold tracking-wide font-['Playfair-display']">Pickup in Surfside, FL</p>
        </div>
  
        <form onSubmit={handleSubmit} className="w-full mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div className="sm:col-span-2 shrink-1 justify-between flex items-center">
            <label htmlFor="gummies-quantity" className="block text-2xl lg:text-3xl font-medium text-sky-900 font-['Playfair-display']">
              Nutrient-packed Gummies
            </label>

            <IncrementButtons
              name="gummies-quantity"
              count={gumCount}
              increment={incrementGCounter}
              decrement={decrementGCounter}
            />
          </div>

          <div className="sm:col-span-2 justify-between flex items-center">
            <label htmlFor="turmeric-quantity" className="block text-2xl lg:text-3xl font-medium text-sky-900 font-['Playfair-display']">
              Turmeric Tonic (by the jar)
            </label>

            <IncrementButtons
              name="turmeric-quantity"
              count={turmCount}
              increment={incrementTCounter}
              decrement={decrementTCounter}
            />
          </div>

          <div className="sm:col-span-2 relative flex flex-col items-start">
            <label className="mb-2 block text-base font-medium3 text-slate-700">
              Include honey with Turmeric Tonic (no extra charge)
            </label>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="honey"
                  aria-describedby="include-honey"
                  name="honey"
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 text-sky-800 focus:ring-sky-700"
                  defaultChecked={true}
                />
              </div>
              <div className="ml-3 text-base">
                <label htmlFor="honey" className="font-medium text-sky-900">
                  With Honey
                </label>
              </div>
            </div>
          </div>

          <div className="sm:col-span-2 justify-between flex items-center">
            <label htmlFor="turmeric-subscription-quantity" className="block text-2xl lg:text-3xl font-medium text-sky-900 font-['Playfair-display']">
              Turmeric Tonic (monthly subscription)
            </label>

            <IncrementButtons
              name="turmeric-subscription-quantity"
              count={turmSubCount}
              increment={incrementTSubCounter}
              decrement={decrementTSubCounter}
            />
          </div>

          <div className="sm:col-span-2 relative flex flex-col items-start">
            <p className="block text-sky-800 font-bold text-underline">Subscribe and save! For $100 get a total of 8 jars, 2/week.</p>
          </div>

          <div className="sm:col-span-2 relative flex flex-col items-start">
            <label className="mb-2 block text-base font-medium3 text-slate-700">
              Include honey with Turmeric Tonic (no extra charge)
            </label>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="honey-subscription"
                  aria-describedby="include-honey-subscription"
                  name="honey"
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 text-sky-800 focus:ring-sky-700"
                  defaultChecked={true}
                />
              </div>
              <div className="ml-3 text-base">
                <label htmlFor="honey-subscription" className="font-medium text-sky-900">
                  With Honey
                </label>
              </div>
            </div>
          </div>

          <div className="sm:col-span-2 shrink-1 justify-between flex items-center">
            <label htmlFor="banana-bread-quantity" className="block text-2xl lg:text-3xl font-medium text-sky-900 font-['Playfair-display']">
              Banana Bread
            </label>

            <IncrementButtons
              name="banana-bread-quantity"
              count={bananaLoafCount}
              increment={incrementBCounter}
              decrement={decrementBCounter}
            />
          </div>

          <div className="sm:col-span-2 flex justify-between items-center text-xl border-t-2 border-t-slate-100 pt-4">
            <p>Subtotal</p>
            <p>${gumCount*15 + turmCount*20 + turmSubCount*100 + bananaLoafCount*35}</p>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-base font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="py-4 border-2 block w-full rounded-xl border-gray-300 focus:border-gray-500 focus:ring-gray-500 sm:text-lg"
              />
            </div>
          </div>
          
          <div className="sm:col-span-2">
            <div className="flex justify-between">
              <label htmlFor="how-can-we-help" className="block text-base font-medium text-gray-700">
                Additionnal information
              </label>
            </div>
            <div className="mt-1">
              <textarea
                id="how-can-we-help"
                name="how-can-we-help"
                aria-describedby="how-can-we-help-description"
                rows={3}
                className="py-4 border-2 block w-full rounded-xl border-gray-300 focus:border-gray-500 focus:ring-gray-500 sm:text-lg"
                defaultValue={''}
              />
            </div>
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcRpRckAAAAAH5meR7kDUTg7aZGjLKDO_naJzA4"
          />

          <div className="text-center sm:col-span-2">
            <button
              type="submit"
              className="inline-flex justify-center bg-sky-800 font-['Montserrat'] hover:bg-sky-800/80 rounded-xl border border-transparent py-4 px-8 text-lg font-medium text-white shadow-sm hover:bg-back/80 focus:outline-none focus:ring-2 focus:ring-grape-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
          {responseMessage ? 
          <div className="bg-green-100 py-5 px-4 rounded-xl">
            <p className="text-green-800">{responseMessage}</p>
          </div> : null}
        </form>
      </div>
    </div>
  </Layout>
)}

export default Shop
