import React from "react"
import ConditionalLayout from "../components/modal"
import SEO from "../components/seo"
import Detail from "../components/detail"

const data = [
  "Laundry detergent",
  "Stain removal",
  "All purpose cleaner",
  "Disinfectant",
  "Mop",
  "Microfibers",
  "Vacuum",
]

const CleaningPage = () => (
  <ConditionalLayout>
    <SEO title="Laundry & Cleaning" />
    <Detail
      name="Laundry & Cleaning"
      subtitle="You breathe in all the products you clean your home with. Safe cleaning supplies is a must! Your skin is your biggest organ so the clothes we put on and the detergents we use to clean them are fundamental. I help you pick out products that are safe even for the most sensitive and reactive members of your family."
      data={data}
    />
  </ConditionalLayout>
)

export default CleaningPage
