import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "gatsby";

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)
  const btnRef = React.useRef();

  React.useEffect(() => {
    const closeDropdown = e => {
      const composed = e.composedPath()
      if(composed.includes(btnRef.current) === false) {
        toggleExpansion(false);
      }
    }
    document.body.addEventListener("click", closeDropdown);

    return(
      () => document.body.removeEventListener("click", closeDropdown)
    );
  }, []);

  return (
    <nav className="z-10 fixed top-0 left-0 right-0 transition-all ease-in-out duration-700 bg-white">
      <div className="py-8 px-4 sm:px-0 border-b-2 border-b-slate-100 container mx-auto lg:flex lg:justify-between lg:items-center">
        <div className="flex items-center justify-between">
            <div>
              <Link to={`/`} className="font-['Playfair-display'] text-2xl font-bold text-sky-800 lg:text-3xl hover:text-slate-600" href="#">1 Swap at a time</Link>
            </div>

            <div className="flex lg:hidden">
                <button type="button" ref={btnRef}
                    onClick={() => toggleExpansion(!isExpanded)}
                    className="text-sky-800 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                    aria-label="toggle menu">
                    <svg viewBox="0 0 24 24" className="w-8 h-8 fill-current">
                        <path fillRule="evenodd"
                            d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z">
                        </path>
                    </svg>
                </button>
            </div>
        </div>

        <div
          className={
            `${isExpanded ? `flex` : `hidden`}
            font-['Montserrat'] w-full lg:flex gap-5 lg:gap-[5rem] lg:items-center lg:w-auto text-xl flex-col mt-6 lg:-mx-6 lg:mt-0 lg:flex-row font-normal`}
        >
            <a className="tracking-wider nav-header pb-2 text-sky-800 hover:text-sky-800"
                href="/#services">Services</a>
            <a className="tracking-wider nav-header pb-2 text-sky-800 hover:text-sky-800"
                href="/#about-me">About</a>
            <a className="tracking-wider nav-header pb-2 text-sky-800 hover:text-sky-800"
            href="/#contact">Contact</a>
            <Link to={`/shop`} className="tracking-wider nav-header pb-2 text-sky-800 hover:text-sky-800" href="#">Shop</Link>
        </div>

        <a className="tracking-wider font-['Montserrat'] hidden lg:block font-medium text-xl px-5 py-3 mt-4 text-center text-sky-800 capitalize transition-colors duration-200 transform border rounded-md lg:mt-0 hover:bg-gray-100 lg:w-auto border-sky-800"
            href="#contact">
            CONTACT US
        </a>
      </div>
    </nav>
  )

  // return (
  //   <nav className="flex py-8 mb-6 bg-white items-center justify-center">
  //     <div className="flex flex-grow items-center justify-between max-w-7xl">
  //       <div className="flex items-center flex-shrink-0 mr-6 text-brown">
  //         <span className="text-4xl font-extrabold tracking-tight">
  //           {siteTitle}
  //         </span>
  //       </div>
  //       <div className="block lg:hidden">
  //         <button
  //           onClick={() => toggleExpansion(!isExpanded)}
  //           className="flex items-center px-3 py-2 text-white border border-white rounded hover:text-white hover:border-white"
  //         >
  //           <svg
  //             className="w-3 h-3 fill-current"
  //             viewBox="0 0 20 20"
  //             xmlns="http://www.w3.org/2000/svg"
  //           >
  //             <title>Menu</title>
  //             <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  //           </svg>
  //         </button>
  //       </div>
  //       <div
  //         className={`${
  //           isExpanded ? `block` : `hidden`
  //         } w-full block lg:flex lg:items-center lg:w-auto`}
  //       >
  //         <div className="text-xl font-semibold">
  //           <Link
  //             to={`/`}
  //             href="#responsive-header"
  //             className="block mt-4 mr-4 lg:inline-block lg:mt-0 text-brown hover:text-opacity-70"
  //           >
  //             About
  //           </Link>
  //           <Link
  //             to={`/page-2`}
  //             className="block mt-4 mr-4 lg:inline-block lg:mt-0 text-brown hover:text-opacity-70"
  //           >
  //             Services
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //   </nav>
  // )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
