import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import Carousel from "../components/carousel"
import Contact from "../components/contact"
import Shop from "../components/shop"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Carousel />
    <About />
    <Contact />
  </Layout>
)

export default IndexPage
